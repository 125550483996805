
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import timeout from '@/assets/timeout.svg';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrBtn }
})
export default class SessionTimeoutView extends PwrVue {
  get img() {
    return timeout;
  }

  get sessionWatcher(): string {
    return this.$route.params.sessionWatcher ?? '';
  }

  private mounted(): void {
    if (this.sessionWatcher.length === 0) {
      this.goBack();
    }
  }
}
